<template>
  <div class="space-y-5">
    <div class="flex items-center justify-between flex-row-reverse lg:flex-row">
      <h1 class="page_title">{{ $route.meta.title }}</h1>

      <router-link v-if="is_API_loaded" :to="{ name: 'new-membership' }">
        <vs-button size="large"><span class="font-medium">新增會員等級</span></vs-button>
      </router-link>

      <!-- 骨架屏 -->
      <skeleton v-else width="82px" />
    </div>

    <main v-if="is_API_loaded" class="bg-white rounded-2xl w-full">
      <section class="text-sm overflow-x-auto">
        <div class="text-gray-text_dark font-medium flex items-center px-4 h-14 w-min sm:w-full">
          <vs-checkbox v-if="indeterminate" indeterminate v-model="indeterminate" class="checkbox_section" @click="indeterminateHandleClick"
            ><span class="text-xs">已選取 {{ selected.length }} 筆</span></vs-checkbox
          >

          <vs-checkbox v-else class="checkbox_section" v-model="selectAll" @click="checkAllHandleClick">
            <span v-if="selectAll" class="text-xs">已選取 {{ selected.length }} 筆</span>
            <span v-else class="text-xs">共 {{ memberships.length }} 筆</span>
          </vs-checkbox>

          <transition name="fade">
            <div v-if="indeterminate || selectAll" class="title_section" key="1">
              <div class="w-max border border-gray-border rounded-xl grid grid-flow-col h-8 divide-x divide-gray-border overflow-hidden">
                <button @click="delete_dialog = true" class="px-4 hover:bg-gray-background font-medium">刪除</button>
              </div>
            </div>
            <div v-else class="title_section" key="2">
              <span class="title">等級名稱</span>
              <span class="w-36">會員折扣</span>
              <span style="width: 187px">升級門檻</span>
            </div>
          </transition>
        </div>

        <div class="flex flex-col divide-y divide-gray-background text-gray-text_light w-min sm:w-full">
          <div v-for="item in memberships" :key="item.smvno">
            <router-link
              :to="{
                name: 'edit-membership',
                params: {
                  id: item.smvno,
                },
              }"
              class="flex items-center h-14 hover:bg-gray-background transition-colors cursor-pointer px-4 relative group"
            >
              <vs-checkbox class="checkbox_section" @click.stop="checkBoxHandleClick" v-model="selected" :val="item.smvno"></vs-checkbox>
              <div class="title_section">
                <span class="title text-limit-1">{{ item.title }}</span>

                <div class="w-36">
                  <span v-if="item.discount" class="bg-primary text-white rounded-full px-3 py-1">{{
                    item.discount | filter_discount
                  }}</span>
                  <span v-else class="bg-gray-background text-gray-text_light rounded-full px-3 py-1">無折扣</span>
                </div>
                <span style="width: 187px">NT${{ item.pays | currency }}</span>
              </div>
            </router-link>
          </div>
        </div>
      </section>
    </main>

    <!-- 骨架屏區塊 -->
    <main v-else class="bg-white rounded-2xl w-full pt-4">
      <section class="flex space-x-3 px-4 mb-2">
        <skeleton />
        <skeleton class="sm:flex-shrink-0" :width="200" />
      </section>
      <section class="text-sm overflow-x-auto">
        <div v-for="(item, index) in 3" :key="index" class="text-gray-text_dark font-medium flex items-center px-4 h-14 w-full">
          <div class="checkbox_section">
            <skeleton :width="23" :height="23" />
          </div>
          <skeleton width="100%" height="23px" />
        </div>
      </section>
    </main>

    <!-- pagination -->
    <div v-if="pagination.tpages > 1" class="flex flex-col items-center space-y-2">
      <vs-pagination @click.native="changePagination" not-arrows v-model="currentPage" :length="pagination.tpages" />
      <span class="text-xs text-gray-400">總共 {{ pagination.pcounts }} 筆</span>
    </div>

    <!-- 刪除等級 dialog -->
    <vs-dialog overflow-hidden blur v-model="delete_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要刪除 {{ selected.length }} 筆會員等級？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">刪除的會員等級無法復原，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="delete_dialog = false">取消</vs-button>
          <vs-button @click="bathDelete" size="large" transparent color="danger" ref="deleteBtn">刪除</vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
export default {
  name: 'memberships',
  data() {
    return {
      is_API_loaded: false, // 判斷並呈現骨架屏
      selectAll: false,
      indeterminate: false,
      selected: [],
      memberships: [],
      pagination: {},
      currentPage: 1,
      delete_dialog: false, // 刪除會員等級 dialog
      defaultQuery: {}, // 預設$route.query
    }
  },
  filters: {
    filter_discount(value) {
      return (100 - value) / 10 + '折'
    },
  },
  computed: {
    // 把多選的checkbox陣列轉字串
    _selectedToStr() {
      return this.selected.toString()
    },
  },
  created() {
    this.defaultQuery = this.$route.query
    this.loadMemberships()
  },
  methods: {
    // 改變頁籤時觸發
    changePagination() {
      this.selected = []
      this.selectAll = false
      this.indeterminate = false
      this.loadMemberships(['page', this.currentPage])
    },
    // 3.10.2.1 讀取會員等級列表
    loadMemberships(arr) {
      // 深拷貝
      let routeQuery = JSON.parse(JSON.stringify(this.$route.query))
      if (arr) routeQuery[arr[0]] = arr[1] // 新增進物件
      // 迴圈，若物件中有空值就刪除
      for (let key in routeQuery) {
        // 若值為空，刪除。
        if (routeQuery[key] === '') {
          delete routeQuery[key]
        }
      }

      // 判斷物件是否一樣
      function objCompare(obj1, obj2) {
        const obj1_keys = Object.keys(obj1)
        const obj2_keys = Object.keys(obj2)
        if (obj1_keys.length !== obj2_keys.length) {
          return false
        }
        for (let key of obj1_keys) {
          if (obj1[key] !== obj2[key]) {
            return false
          }
        }
        return true
      }

      if (!objCompare(routeQuery, this.defaultQuery)) {
        // 不一樣，重整query
        this.$router.replace({ query: routeQuery })
        this.defaultQuery = routeQuery
      }

      this.$axios({
        url: 'front/store/mvip/getMVipList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          page: routeQuery.page ? routeQuery.page : 1,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.is_API_loaded = true
          this.memberships = res.data.Data
          this.pagination = res.data.Page
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 批次操作 刪除
    bathDelete() {
      const loading = this.$vs.loading({
        target: this.$refs.deleteBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/mvip/dMVipData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          smvno: this._selectedToStr,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.selected = []
          this.indeterminate = false
          this.selectAll = false
          loading.close() // 關閉按鈕loading
          this.delete_dialog = false // 關閉dialog
          this.loadMemberships(1)
          this.$myVS.openNoti(res.data.Message, '', undefined)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close() // 關閉按鈕loading
        }
      })
    },
    checkBoxHandleClick(e) {
      setTimeout(() => {
        if (this.selected.length > 0 && this.selected.length < this.memberships.length) {
          // 勾選卻沒有全選時
          this.indeterminate = true
        } else if (this.selected.length === this.memberships.length) {
          // 全選時
          this.indeterminate = false
          this.$nextTick(() => {
            this.selectAll = true
          })
        } else {
          // 沒選時
          this.indeterminate = false
          this.selectAll = false
        }
      }, 0)
    },
    checkAllHandleClick(e, status) {
      let value
      e ? (value = e.target.checked) : (value = status)
      if (value) {
        for (let i = 0; i < this.memberships.length; i++) {
          this.selected.push(this.memberships[i].smvno)
        }
      } else {
        this.selected = []
      }
    },
    indeterminateHandleClick(e) {
      let value = e.target.checked
      this.checkAllHandleClick(null, value)
    },
  },
}
</script>

<style lang="scss" scoped>
.checkbox_section {
  @apply w-28 flex-shrink-0;
}

.title_section {
  @apply flex items-center w-min sm:w-full;
  .title {
    @apply w-48 sm:flex-grow;
  }
}
</style>
